<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <!--{{ typeof(slopeParams[0].width) }}-->
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
           ${defaultSvgParams.height * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <!-- <defs v-if="!isNav">
        <filter x="-25%" y="-30%" width="1.5" height="1.6" id="text-bg">
          <feFlood flood-color="#fff" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
            <feMorphology in="SourceAlpha" operator="dilate" radius="4" />
          </feMerge>
        </filter>
      </defs>-->
      <ruler
        :x1="0"
        :y1="changeHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightDown"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="valleyY"
        :x2="defaultSvgParams.width"
        :y2="valleyY"
        :color="isDetails ? colors.grey : colors.blue"
        v-if="!isNav && isOffsetValleyNeed"
      />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="violet" v-if="!isNav" />
      <size-arrow :path="arrowVertical" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size-arrow
        :path="arrowValleyOffset"
        :is-details="isDetails"
        color="blue"
        v-if="!isNav && isOffsetValleyNeed"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="changeHeightUp + (changeHeightDown - changeHeightUp) / 2"
        :text="`A = ${cathetHeight}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown + 30"
        :text="`B = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.violet"
      />
      <size
        v-if="!isNav && isOffsetValleyNeed"
        :x="changeWidthLeft - 30"
        :y="valleyY - (valleyY - changeHeightDown) / 2"
        :text="`E = ${slopeParams.offsetValley}`"
        :fill="isDetails ? colors.grey : colors.blue"
      />
      <defs v-if="isPlate">
        <clipPath :id="`figureMask--sector-${slopeFromState.uid}`">
          <path
            :d="triangleMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // triangle mask
        </clipPath>
      </defs>
      <g
        v-if="isDetails"
        :clip-path="`url(#figureMask--sector-${slopeFromState.uid})`"
        name="rowsForTooltip"
      >
        <!--clip-path="url(#figureMask)"-->
        <g
          :transform="`rotate(-${platesRotationDegree}
          ${changeWidthRight} ${changeHeightDown})`"
        >
          <!--          :transform="`rotate(-${platesRotationDegree}
          ${changeWidthRight} ${changeHeightDown})`"-->
          <g v-for="i in rowsCount" :key="i" class="row__overlay">
            <rect
              :x="changeWidthRight - plateWidth * i"
              :y="
                i % 2 === 0
                  ? changeHeightDown - rowHeight - plateHeight / 2
                  : changeHeightDown - rowHeight
              "
              :width="plateWidth - 1"
              :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
              fill="#F6F6F6"
              stroke="#999999"
              stroke-width="1"
              @mouseover="showRowInfo(i)"
              @mouseout="hideRowInfo()"
            ></rect>
            <line
              :x1="changeWidthRight - plateWidth * i"
              :y1="
                i % 2 === 0
                  ? changeHeightDown - rowHeight + plateHeight * n - plateHeight / 2
                  : changeHeightDown - rowHeight + plateHeight * n
              "
              :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
              :y2="
                i % 2 === 0
                  ? changeHeightDown - rowHeight + plateHeight * n - plateHeight / 2
                  : changeHeightDown - rowHeight + plateHeight * n
              "
              stroke="#999999"
              stroke-width="1"
              v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
              :key="n"
            ></line>
            <!--(plateHeight * n + (plateHeight / 2)) + shift-->
          </g>
        </g>
        <g :transform="`rotate(${-platesRotationDegree} ${changeWidthLeft} ${changeHeightDown})`">
          <!--
          :transform="`rotate(${-platesRotationDegree} ${changeWidthLeft} ${changeHeightDown})`"
          -->
          <row-number
            :opts="getRowNumberOpts(i)"
            v-for="i in rowsCount"
            :key="`row-number--${i}`"
          />
        </g>
      </g>
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
        :transform="`rotate(${arrowDirectionRotationDegree}
            ${arrowLength - arrowBias} ${arrowDirectionY})`"
      />
      <path
        :d="triangle"
        class="scheme-circuit"
        fill="none"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
    </svg>
  </div>
</template>

<script>
import RowNumber from '@/components/figure_elements/RowNumber'
import {
  getRowNumberXForTriangles,
  getRowNumberFontSize,
  colors,
  drawSizeArrowVertical,
  getCathetSize,
  drawSizeArrowHorizontal,
  drawArrowDirection,
  getValleyY
} from '@/utils/drawing'
import Ruler from '@/components/figure_elements/Ruler'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Size from '@/components/figure_elements/Size'
import { getMin } from '@/utils'
export default {
  name: 'triangle',
  props: {
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isOffsetValleyNeed: {
      type: Boolean
    },
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Size,
    SizeArrow,
    Ruler,
    RowNumber
  },
  methods: {
    getRowNumberOpts(i) {
      const { changeWidthLeft, plateWidth, scale } = this
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberXForTriangles(
          changeWidthLeft,
          plateWidth,
          i,
          this.slopeFromState.slopeParams[0].width,
          scale,
          this.slopeParams.ratio
        ),
        y: this.changeHeightDown,
        text: i
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    arrowVertical() {
      const { changeHeightUp, changeHeightDown, changeWidthLeft } = this
      const x = changeWidthLeft - 30
      return drawSizeArrowVertical(x, changeHeightUp, changeHeightDown)
    },
    arrowValleyOffset() {
      const { changeHeightDown, changeWidthLeft, valleyY } = this
      const x = changeWidthLeft - 30
      return drawSizeArrowVertical(x, changeHeightDown, valleyY)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState.slopeParams[0]
    },
    halfPlate() {
      return this.columnsCount % 2 === 0
        ? 0
        : (this.slopeFromState.materialSize.height / 1000 / 2) * this.scale
    },
    rowsCount() {
      return Math.ceil(
        ((this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio) *
          1000) /
          this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return Math.ceil(
        (Math.sqrt(
          this.slopeFromState.slopeParams[0].width ** 2 +
            (this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio) **
              2
        ) *
          this.scale) /
          ((this.slopeFromState.materialSize.height / 1000) * this.scale)
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    platesRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return 90 - (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },

    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 5 : 10
    },
    arrowWidth() {
      const { changeHeightDown, changeWidthLeft, changeWidthRight } = this
      const y = changeHeightDown + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowDirection() {
      const { arrowDirectionStartX, arrowDirectionEndX, arrowDirectionY, arrowDirectionSize } = this
      return drawArrowDirection(
        arrowDirectionStartX,
        arrowDirectionEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    triangle() {
      const { changeWidthLeft, changeHeightUp, changeHeightDown, changeWidthRight } = this
      return `M${changeWidthLeft},${changeHeightUp}
      L${changeWidthRight},${changeHeightDown}
      M${changeWidthRight},${changeHeightDown}
      L${changeWidthLeft},${changeHeightDown}
      M${changeWidthLeft},${changeHeightDown}
      L${changeWidthLeft},${changeHeightUp}
      M${changeWidthLeft},${changeHeightUp}`
    },
    triangleMask() {
      const { changeWidthLeft, changeHeightUp, changeHeightDown, changeWidthRight } = this
      return `M${changeWidthLeft},${changeHeightUp}
      L${changeWidthRight},${changeHeightDown}
      L${changeWidthLeft},${changeHeightDown}
      L${changeWidthLeft},${changeHeightUp} Z`
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) /
        (this.slopeParams.width / this.slopeParams.ratio)
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      // return this.scaleW > this.scaleH ? this.scaleH : this.scaleW
      return getMin(this.scaleW, this.scaleH)
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 -
        ((this.slopeParams.width / this.slopeParams.ratio) * this.scale) / 2
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        ((this.slopeParams.width / this.slopeParams.ratio) * this.scale) / 2
      )
    },
    arrowDirectionStartX() {
      // изменяем ширину направляющей стрелки влево
      return this.arrowLength - this.arrowBias + this.arrowNewLength * 0.2
    },
    arrowDirectionEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return this.arrowLength - this.arrowBias + this.arrowNewLength - this.arrowNewLength * 0.2
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return this.changeHeightDown
    },
    arrowLength() {
      return (
        this.changeWidthLeft + (this.slopeParams.width * this.scale) / (this.slopeParams.ratio + 1)
      )
    },
    arrowG() {
      return this.arrowLength * Math.sqrt(2) - 20
    },
    arrowNewLength() {
      return Math.sqrt(this.arrowLength ** 2 + this.arrowBias ** 2) - this.changeWidthLeft
    },
    arrowBias() {
      return (
        (this.slopeParams.width * this.scale) /
        (this.slopeParams.ratio + 1) /
        this.slopeParams.ratio
      )
    },
    arrowDirectionRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI - 90
    },
    cathetHeight() {
      return getCathetSize(this.slopeParams.width, this.slopeParams.ratio)
    },
    valleyY() {
      const { defaultSvgParams, slopeParams, changeHeightDown } = this
      return getValleyY(defaultSvgParams.height, slopeParams, changeHeightDown)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass"></style>
